import styled from "styled-components";
import {ReactComponent as CheckCircle} from "../../assets/icons/check-circle.svg";
import {ReactComponent as CloseCircle} from "../../assets/icons/close-circle.svg";
import {ReactComponent as Clock} from "../../assets/icons/clock.svg";
import {Body} from "../Texts/Texts";
import {Spinner} from '../Spinner/Spinner';
import {Await} from 'react-router-dom';
import React from 'react';

const Box = styled.div`
    display: flex;
    ${props => props.$margin && 'margin-bottom: 15px'};
    ${Body} { margin: 0; align-self: center; }
`;

const StyledCheckCircle = styled(CheckCircle)`
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    path { fill: var(--color-tertiary) }
`;

const StyledCloseCircle = styled(CloseCircle)`
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    path { fill: var(--color-secondary) }
`;

const StyledClock = styled(Clock)`
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    path { fill: var(--color-accent) }
`;

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
`;

export const ProductAvailability = ({data, ...props}) => {
    return (

        <React.Suspense fallback={<ProductAvailabilityFallback {...props} />}>
            <Await resolve={data} errorElement={<></>}>
                {(shopsData) => (
                    <Box {...props}>
                        {shopsData?.productAvailabilityType === "upcoming" ? <StyledClock />
                            : shopsData?.productAvailabilityType === "available" ? <StyledCheckCircle/>
                            : shopsData?.productAvailabilityType === "unavailable" ? <StyledCloseCircle />
                            : null
                        }
                        {shopsData?.productAvailabilityText && <Body>{shopsData?.productAvailabilityText}</Body>}
                    </Box>
                )}
            </Await>
        </React.Suspense>
    )
}

export const ProductAvailabilityFallback = ({...props}) => {
    return (
        <Box {...props}>
            <SpinnerWrapper>
                <Spinner $small />
            </SpinnerWrapper>
            <Body>Beschikbaarheid aan het ophalen</Body>
        </Box>
    )
}